export default function () {
  const website = window.location.hostname;
  const internalLinkRegex = new RegExp(`^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?${website})|(localhost:d{4})|(/.*))(/.*)?$`, '');
  const elements = document.querySelectorAll('a');

  elements.forEach((element) => {
    const href = element.getAttribute('href');

    if (
      !internalLinkRegex.test(href)
      && href
      && !href.startsWith('#')
      && !href.startsWith('mailto:')
      && !href.startsWith('tel:')
      && href !== ''
    ) {
      element.setAttribute('target', '_blank');
      element.setAttribute('rel', 'noopener');
    }
  });
}
