import $ from 'jquery';

/* eslint-disable */
export default function () {
  if (document.documentElement.lang.toLowerCase() === 'fr') {
    const $translationItems = $('.js-translate, .form-check-label');

    const translations = {
      'Identificatie': 'Identification',
      'Batterij informatie': 'Informations sur la batterie',
      'Naam van het punt van aanvaarding / Naam van erkend centrum': 'Nom du point de collecte / Nom du centre agree',
      'Contactpersoon': 'Personne de contact',
      'E-mailadres': 'Adresse email',
      'Telefoonnummer': 'Numéro de téléphone',
      'Informatie over batterijen': 'Information sur les batteries',
      'Datum van het verzoek om inzameling': 'Date de demande de la collecte',
      'Datum vanaf wanneer de batterij kan worden verwijderd': 'Date à partir de laquelle la batterie peut être enlevée',
      'Batterijtype': 'Type de batterie',
      'Volledig': 'Complète',
      'Stapels': 'Empilement (stacks)',
      'Cel(len)': 'Cellule(s)',
      'Module(s)': 'Module(s)',
      'Chemie van de batterij': 'Chimie de la batterie',
      'Li-Ion (NMC)': 'Li-Ion (NMC)',
      'Li-Ion (LFP)': 'Li-Ion (LFP)',
      'Li-Ion (LMO)': 'Li-Ion (LMO)',
      'Li-Ion (NCA)': 'Li-Ion (NCA)',
      'Li-Ion (LCO)': 'Li-Ion (LCO)',
      'Li-Ion (LTO)': 'Li-Ion (LTO)',
      'NiMh': 'NiMh',
      'NiCd': 'NiCd',
      'Identificatienummer batterij': 'Numéro d’identification de la batterie',
      'Merk batterij': 'Marque de la batterie',
      'Netto gewicht per eenheid in KG': 'Poids net unitaire en KG',
      'Aantal identieke batterijen/modules': 'Nombre de batteries/modules identiques',
      'Afmetingen van een module (lengte x breedte x hoogte in cm)': 'Dimensions d’un module (longueur x largeur x hauteur en cm)',
      'Voertuig identificatie': 'Identification du véhicule',
      'Merk van het voertuig': 'Marque du véhicule',
      'Voertuigmodel': 'Modèle du véhicule',
      'Chassisnummer (VIN)': 'Numéro de chassis (VIN)',
      'Veiligheidsinformatie': 'Informations de sécurité',
      'Staat van lading (SoC): %': 'Etat de charge (SoC) : %',
      'Algemene toestand van de batterij (SoH): %': 'Etat général de la batterie (SoH) : %',
      'Visuele toestand van de batterij': 'Etat visuel de la batterie',
      'Beschadigd': 'Endommagée',
      'Niet beschadigd': 'Non endommagée',
      'Werkingsstatus batterij': 'Etat de fonctionnement de la batterie',
      'Defect': 'Défectueuse',
      'Niet defect': 'Non défectueuse',
      'Aard van de storing (alleen indien antwoord = defect)': 'Nature du dysfonctionnement (uniquement si réponse = défectueuse)',
      'BMS': 'BMS',
      'Visuele vervorming': 'Déformation visuelle',
      'Oververhitting': 'Surchauffe',
      'Lekkage': 'Fuite',
      'Corrosie': 'Corrosion',
      'Andere': 'Autre',
      'Andere aard van de storing': 'Autre nature du dysfonctionnement',
      'Verpakking': 'Emballage',
      'Originele verpakking beschikbaar': 'Emballage d\'origine disponsible',
      'Gepalletiseerde verpakking beschikbaar': 'Emballage sur palette disponible',
      'Geen verpakking beschikbaar': 'Pas d’emballage disponible',
      'Opmerkingen': 'Remarques',
      'Fotobijlage': 'Photos',
      'ADR classificatie': 'Classification ADR',
      'UN': 'UN',
      'Klasse': 'Classe',
      'Verpakkingsgroep': 'groupe d\'emballage',
      'Alle batterijen zijn beschouwd als proven type/serial production. Vermelden indien "prototype", of "onbekend" als gevaar classificatie.': 'Toutes les batteries sont considérées comme produits en série (proven type). Indiquer si le statut est inconu, ou prototype (classification dangereuse).',
      'Finale evaluatie en bevestiging veilige voor transport batterij': 'Evaluation finale et confirmation sécurité pour le transport',
      'Bevestigingen': 'Confirmations',
      'Veiligheidsbevestiging voor vervoer': 'Confirmation de sécurité pour le transport',
      'Veilig voor vervoer': 'Sans risque pour le transport',
      'Met transportrisico': 'Avec risque pour le transport',
      'Voorwaarden en bepalingen': 'Termes et conditions',
      'Ik aanvaard de algemene voorwaarden van Febelauto, en deze zijn bindend voor mij': 'J’accepte les termes et conditions de Febelauto, et ils me sont opposables',
      'Verzenden': 'Envoyer',
      'Door het invullen en registreren gaat u akkoord met de algemene voorwaarden & zijn deze tegen u stelbaar.': 'En complétant et en vous inscrivant, vous acceptez les termes et conditions et ils sont réglables contre vous.',
      'Bedrijfsgegevens': 'Détails de l\'entreprise',
    }

    $translationItems.each((index, item) => {
      const translation = translations[item.innerText];

      if (translation) {
        item.innerText = translation;
        $(item).css('opacity', 1);
      } else {
        $(item).css('opacity', 1);
      }
    });
  }
}
