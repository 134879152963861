import $ from 'jquery';
import 'modaal/dist/js/modaal';
import { matchLarge } from '../../constants/defaults';

export default function () {
  $('.main-nav__trigger').modaal({
    background_scroll: false,
    fullscreen: true,
    overlay_opacity: 0.5,
    content_source: '#modal-menu',
    after_callback_delay: 250,
    hide_close: true,
    custom_class: 'modal-menu',
  });

  $('body').on('click', (e) => {
    const target = $(e.target);

    if (target.is('.modaal-overlay')) {
      $('.main-nav__trigger').modaal('close');
    }
  });

  matchLarge.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $('.main-nav__trigger').modaal('close');
    }
  });

  $('.js-modaal-close').on('click', () => {
    $('.main-nav__trigger').modaal('close');
  });

  document.querySelectorAll('nav a').forEach((elem) => {
    const tolerance = 5;
    const left = 0;

    elem.onmouseenter = (e) => { // eslint-disable-line
      const right = elem.clientWidth;
      let x = e.offsetX - elem.offsetLeft;

      if (x - tolerance < left) {
        x = left;
      }

      if (x + tolerance > right) {
        x = right;
      }

      elem.style.setProperty('--x', `${x}px`);
    };
  });
}
