export default class Event {
  constructor() {
    const scrollToForm = document.querySelector('.js-scroll-to-form');
    const scrollTo = document.querySelector('.js-scroll-to');

    if (scrollTo) {
      scrollTo.scrollIntoView();
    }

    if (scrollToForm && scrollToForm.previousElementSibling) {
      scrollToForm.previousElementSibling.scrollIntoView();
    }
  }
}
