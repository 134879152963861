import $ from 'jquery';

export default function () {
  const offset = 15;

  const scrollTo = (hash) => {
    const target = $(hash);

    if (target.length) {
      const position = target.offset().top;

      $('body, html').animate({
        scrollTop: `${position - offset}px`,
      }, 500);

      window.location.hash = hash;
    }
  };

  $('a[href^="#"]').on('click', function (e) { // eslint-disable-line func-names
    e.preventDefault();
    const hash = $(this).attr('href');
    scrollTo(hash);
  });

  if (window.location.hash !== '') {
    scrollTo(window.location.hash);
  }
}
