import $ from 'jquery';
import googleMapsStyles from '../../constants/googleMapsStyles';
import Infobox from './infobox';
import { MarkerClusterer } from './MarkerClusterer';

const defaultMapData = {
  holderId: 'store-locator-map',
  lat: 51.0325538,
  long: 3.7333816,
};

export default class StoreLocator {
  init(mapSettings = null) {
    const google = window.google || {};
    this.googleMaps = google.maps;
    this.baseUrl = window.baseUrl || '';
    this.listeners = new Map();
    this.googleMarkers = [];

    // override default map data if param is set
    const mapData = mapSettings || defaultMapData;
    const holder = document.getElementById(mapData.holderId);

    if (holder) {
      this.map = this.addMap(holder, mapData.lat, mapData.long);

      this.getLocations();
      this.search();
    }
  }

  getLocations() {
    $.getJSON(`${window.SITE_URL}/locations.json`, (data) => {
      this.markers = data.data;
      for (let i = 0; i < this.markers.length; i += 1) {
        const marker = this.markers[i];
        marker.id = `${marker.title}-${marker.address.longitude}-${marker.address.latitude}`.replace(/[^a-zA-Z0-9-]/g, '');
        const { googleMarker, typeClasses } = this.addMarker(marker, false);
        if (googleMarker) {
          this.googleMarkers.push({ googleMarker, typeClasses, id: marker.id });
        }
      }
      this.emit('list-available');
      const markerCluster = new MarkerClusterer(this.map, this.googleMarkers.map( el => el.googleMarker ), // eslint-disable-line
        { imagePath: window.markerImgCluster });
      markerCluster.setStyles(markerCluster.getStyles().map((style) => {
        const newStyle = style;
        newStyle.textColor = '#fff';
        newStyle.width = 37;
        newStyle.height = 37;
        return newStyle;
      }));
    });
  }

  addListener(label, callback) {
    if (!this.listeners.has(label)) {
      this.listeners.set(label, []);
    }
    this.listeners.get(label).push(callback);
  }

  emit(label, ...args) {
    const listeners = this.listeners.get(label);
    if (listeners && listeners.length) {
      listeners.forEach((listenerCallback) => {
        listenerCallback(...args);
      });
      return true;
    }
    return false;
  }

  search() {
    const $list = $('#locations-list');

    $('#locations-search').submit((event) => {
      event.preventDefault();

      $list.addClass('loading');

      const search = `${$('#location-search-field').val()}, Belgium`;
      const geocoder = new this.googleMaps.Geocoder();

      geocoder.geocode({ address: search },
        (data, status) => {
          $list.removeClass('loading');

          if (status === this.googleMaps.GeocoderStatus.OK) {
            const latitude = data[0].geometry.location.lat();
            const longitude = data[0].geometry.location.lng();
            const markerData = {
              id: `${longitude}-${latitude}`,
              address: {
                latitude,
                longitude,
              },
            };

            // add marker and center map
            this.addMarker(markerData, true);

            // reorder list
            for (let i = 0; i < this.markers.length; i += 1) {
              const marker = this.markers[i];
              const distance = this.calculateDistance(
                latitude,
                longitude,
                marker.address.latitude,
                marker.address.longitude,
              );

              const $marker = $(`#${marker.id}`);
              $marker.attr('data-distance', distance);
              const distanceString = `${Math.round(distance / 100) / 10} km`.replace('.', ',');
              $marker.find('.location-list__distance').html(distanceString);
            }

            $list.find('li').sort((a, b) => {
              const distanceA = $(a).attr('data-distance');
              const distanceB = $(b).attr('data-distance');
              return parseInt(distanceA, 10) - parseInt(distanceB, 10);
            }).appendTo($list);
          } else {
            // Error handling
          }
        });
    });
  }

  addMap(holder, latitude, longitude) {
    const zoom = 9;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(
      googleMapsStyles,
      { name: 'Styled Map' },
    );
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    // this.googleMaps.event.addDomListener(window, 'resize', () => {
    //   map.setCenter(mapCenter);
    // });

    this.infobox = new Infobox({
      content: '',
      disableAutoPan: false,
      maxWidth: 180,
      pixelOffset: new this.googleMaps.Size(-175, -330),
      zIndex: null,
      boxStyle: {
        width: '350px',
        height: '270px',
      },
      closeBoxMargin: '0 0 2px 4px',
      // closeBoxURL: '../assets/images/close.png',
      infoBoxClearance: new this.googleMaps.Size(1, 1),
    });

    return map;
  }

  addMarker(data, isHome) {
    const {
      id,
    } = data;

    let service = data.service ? data.service.value : '';
    if (isHome) {
      service = 'home';
    }

    const name = (data.title) ? data.title : '';
    const address = (data.address.addressLine2) ? data.address.addressLine2 : '';
    const locality = (data.address.locality) ? data.address.locality : '';
    const postalCode = (data.address.postalCode) ? data.address.postalCode : '';
    let website = (data.website) ? data.website.trim() : '';
    if (website.length) {
      if (website.indexOf('http://') < 0 && website.indexOf('https://') < 0) {
        website = `http://${website}`;
      }
    } else {
      website = '/';
    }

    const phoneNumber = (data.phone) ? data.phone : '/';
    const email = (data.email) ? data.email : '/';
    let notification = '';
    if (service === 'dismantle') {
      if (window.location.href.indexOf('/fr/') > 0) {
        notification = '<p><strong>Remarque: ce centre ne délivre pas un certificat de destruction</strong></p>';
      } else {
        notification = '<p><strong>Opgelet: dit centrum geeft geen certificaat van vernietiging</strong></p>';
      }
    }
    let types = '';
    let typeClasses = '';
    if (data.type && data.type[0]) {
      types = data.type[0].label;
      if (data.type[0].label.toLowerCase().indexOf('water') >= 0) {
        typeClasses = 'type-water';
      } else {
        typeClasses = 'type-sewer';
      }
    }
    if (data.type && data.type[1]) {
      for (let i = 1; i < data.type.length; i += 1) {
        types = `${types} / ${data.type[i].label}`;
        if (data.type[i].label.toLowerCase().indexOf('water') >= 0) {
          typeClasses = `${typeClasses} type-water`;
        } else {
          typeClasses = `${typeClasses} type-sewer`;
        }
      }
    }
    const lat = parseFloat(data.address.latitude);
    const long = parseFloat(data.address.longitude);

    const myLatlng = new this.googleMaps.LatLng(lat, long);

    let markerImg;
    let markerSize = new this.googleMaps.Size(48, 64);
    let markerAnchor = new this.googleMaps.Point(12, 32);
    let markerScaledSize = new this.googleMaps.Size(24, 32);

    if (isHome) {
      markerImg = window.markerImgHome; // eslint-disable-line
    } else if (service === 'cars' || service === 'dismantle') {
      markerImg = window.markerImg; // eslint-disable-line
    } else if (service === 'carsDismantle') {
      markerImg = window.markerImgDisassemble; // eslint-disable-line
      markerSize = new this.googleMaps.Size(60, 86);
      markerAnchor = new this.googleMaps.Point(12, 55);
      markerScaledSize = new this.googleMaps.Size(36, 44);
    } else if (service === 'carsBatteriesDismantle') {
      markerImg = window.markerImgBatteriesDisassemble; // eslint-disable-line
      markerSize = new this.googleMaps.Size(80, 86);
      markerAnchor = new this.googleMaps.Point(12, 55);
      markerScaledSize = new this.googleMaps.Size(40, 44);
    } else {
      markerImg = window.markerImgBatteries;
      markerSize = new this.googleMaps.Size(48, 110);
      markerAnchor = new this.googleMaps.Point(12, 55);
      markerScaledSize = new this.googleMaps.Size(24, 55);
    }

    const markerIcon = {
      url: markerImg,
      size: markerSize,
      origin: new this.googleMaps.Point(0, 0),
      anchor: markerAnchor,
      scaledSize: markerScaledSize,
    };

    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map: this.map,
      icon: markerIcon,
    });

    const query = encodeURI(`${name}+${address}`);
    const routeUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;

    const contentList = `
      <svg class="icon icon-chevron-right">
        <use xlink:href="#chevron-right"></use>
      </svg>
      <div class="location-list__title">${name}</div>
      <div class="location-list__address">
        ${address}
        ${postalCode} ${locality}
      </div>
      <div class="location-list__distance"></div>
      ${service === 'carsDismantle' || service === 'carsBatteriesDismantle' ? '<svg class="icon icon-wrench"><use xlink:href="#wrench"></use></svg>' : ''}
      ${service === 'carsBatteries' || service === 'carsBatteriesDismantle' ? '<svg class="icon icon-bolt"><use xlink:href="#bolt"></use></svg>' : ''}
    `;

    const contentBox = `
      <div class="infoBox__title">${name}</div>
      <p>
        ${address.replace(' - ', '<br/>')},
        ${postalCode} ${locality}<br/>
      </p>
      <ul class="infoBox__contact">
        <li>
          <svg class="icon icon-phone">
            <use xlink:href="#phone"></use>
          </svg>
          <span>${phoneNumber}</span>
        </li>
        <li>
          <svg class="icon icon-email">
            <use xlink:href="#email"></use>
          </svg>
          <a href="mailto:${email}">${email}</a>
        </li>
        <li>
          <svg class="icon icon-location-2">
            <use xlink:href="#location-2"></use>
          </svg>
          <span><a href="${website}" target="_blank">${website}</a></span>
        </li>
      </ul><br/>
     ${notification}
      <p><a href="${routeUrl}" target="_blank" style="text-decoration: underline">${document.documentElement.lang.toLowerCase() === 'fr' ? 'Directions' : 'Routebeschrijving'}</a></p>
    `;

    // add list item
    if (!isHome) {
      $('<li/>')
        .attr('id', id)
        .addClass(typeClasses)
        .html(contentList)
        .appendTo('#locations-list')
        .click(() => {
          this.showInfobox(marker, contentBox);
        })
        .hover(() => {
          if (marker.getAnimation() != null) {
            marker.setAnimation(null);
          } else {
            marker.setAnimation(this.googleMaps.Animation.BOUNCE);
          }
        });

      this.googleMaps.event.addListener(marker, 'click', () => { // eslint-disable-line
        this.showInfobox(marker, contentBox);
        return false;
      });
    }

    if (isHome) {
      const centerLat = lat;
      const centerLong = long - 0.007;
      const centerPos = new this.googleMaps.LatLng(centerLat, centerLong);

      this.map.setCenter(centerPos);
      this.map.setZoom(12);

      if (this.homeMarker) {
        this.homeMarker.setMap(null);
      }

      this.homeMarker = marker;
    }
    return { googleMarker: marker, typeClasses };
  }

  showInfobox(marker, content) {
    this.infobox.close();
    this.infobox.setContent(content);
    this.infobox.open(this.map, marker);
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2);
    dist += Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist *= 1609.344; // meter
    dist = Math.round(dist);
    return dist;
  }
}
