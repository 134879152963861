import StoreLocator from '../components/store-locator/StoreLocator';

export default class LocationsIndex {
  constructor() {
    this.storeLocator = new StoreLocator();
    this.init();
  }

  init() {
    this.storeLocator.init();
  }
}
