import $ from 'jquery';

export const scale = () => {
  const $scaleContainer = $('.js-scale-container');

  $scaleContainer.each((index) => {
    const $scaleContents = $($scaleContainer[index]).find('.js-scale-content');
    const containerHeight = $($scaleContainer[index]).height();
    let contentHeight = 0;

    $scaleContents.each((i) => {
      contentHeight += $($scaleContents[i]).outerHeight();
    });

    if (contentHeight > containerHeight) {
      $($scaleContainer[index]).addClass('js-scaled');
    }
  });
};


export default function () {
  if ($('.js-scale-container').length) {
    scale();

    $(window).resize(scale);
  }
}
