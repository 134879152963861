import $ from 'jquery';
import 'css-element-queries/src/ResizeSensor';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar';
import Gumshoe from 'gumshoejs/dist/gumshoe';
import { matchMedium } from '../../constants/defaults';

export default function () {
  let sidebar = false;

  if (document.querySelector('.js-sticky-sidebar')) {
    const gumshoe = new Gumshoe('.js-scrollspy-navigation a', {
      offset: 40,
      reflow: true,
    });

    const initStickySidebar = () => (
      new StickySidebar('.js-sticky-sidebar', {
        containerSelector: '.js-sticky-sidebar-container',
        topSpacing: 115,
        bottomSpacing: 115,
      })
    );

    if (matchMedium && matchMedium.matches) {
      sidebar = initStickySidebar();
      gumshoe.setup();
    }

    matchMedium.addListener((mediaQuery) => {
      if (mediaQuery.matches) {
        sidebar = initStickySidebar();
        gumshoe.setup();
      } else {
        sidebar.destroy();
      }
    });
  }

  $('.accordion__header').click(() => {
    if (sidebar) {
      window.setTimeout(() => {
        sidebar.updateSticky();
      }, 300);
    }
  });
}
