import 'objectFitPolyfill/dist/objectFitPolyfill.basic.min';
import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import anchorLinks from './components/layout/anchorLinks';
import sidebar from './components/layout/sidebar';
import targetBlank from './utils/targetBlank';
import animations from './components/layout/animations';
import accordion from './components/layout/accordion';
import matchHeight from './components/layout/matchHeight';
import scaleContent from './components/layout/scaleContent';
import isotope from './components/layout/isotope';
import translate from './components/translate/translate';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
// import gaTracking from './components/tracking/gatracking';

import iFrameResize from './components/iframe/iframeResizer';

import Contact from './pages/Contact';
import LocationsIndex from './pages/LocationsIndex';
import Event from './pages/Event';

require('./utils/nativeConsole');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
anchorLinks();
sidebar();
targetBlank();
animations();
accordion();
matchHeight();
scaleContent();
isotope();
translate();

iFrameResize({ log: true }, '#iframe-placeholder');

// Forms
validation();

// Tracking
// gaTracking();

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  LocationsIndex,
  Event,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}
