import $ from 'jquery';
import Isotope from 'isotope-layout/dist/isotope.pkgd';
import { scale } from './scaleContent';

export default function () {
  const elem = document.querySelector('.js-filter-grid');

  if (elem) {
    const iso = new Isotope(elem, {
      itemSelector: '.js-filter-item',
      layoutMode: 'masonry',
      percentPosition: false,
      masonry: {
        columnWidth: '.large--one-third',
      },
    });

    iso.on('arrangeComplete', (filteredItems) => {
      scale();

      if (filteredItems.length) {
        $('.js-filter-no-results').hide();
      } else {
        $('.js-filter-no-results').show();
      }
    });

    $('.js-filter-button').on('click', (e) => {
      $('.js-filter-button').removeClass('active');

      const $button = $(e.target);
      const filterValue = $button.attr('data-filter');
      const $grid = $('.js-filter-grid');

      $button.addClass('active');

      if (filterValue !== '*') {
        $grid.addClass('js-filtered');
        $grid.find('.grid__item:first-child').addClass('medium--one-half large--one-third');
        $grid.find('.grid__item:first-child').removeClass('large--two-thirds');
      } else {
        $grid.removeClass('js-filtered');
        $grid.find('.grid__item:first-child').addClass('large--two-thirds');
        $grid.find('.grid__item:first-child').removeClass('medium--one-half large--one-third');
      }

      iso.arrange({ filter: filterValue });
    });
  }
}
